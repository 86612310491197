import { captureException, withScope } from "@sentry/node"

const isServer = typeof window === "undefined"
const canTrack = process.env.NEXT_PUBLIC_ENVIRONMENT === "production"

export function sendPageView(url) {
  if (isServer) return
  if (!canTrack) {
    console.debug(`BingAds view: ${url}`)
    return
  }
  try {
    window.uetq.push("event", "page_view", { page_path: url })
  } catch (error) {
    sendToSentry(error)
  }
}

export function sendLeadEvent(email) {
  if (!canTrack) console.debug("BingAds event: Track lead")
  if (isServer || !canTrack) return
  try {
    window.uetq.push("event", "click", {
      event_category: email ? "multistep" : "modal",
      event_label: email ? "lead" : "lead call back"
    })
  } catch (error) {
    sendToSentry(error)
  }
}

function sendToSentry(error) {
  withScope(scope => {
    scope.setFingerprint(["BingAds error"])
    captureException(error)
  })
}
