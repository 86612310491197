import { captureException, withScope } from "@sentry/node"

const canTrack = process.env.NODE_ENV === "production"
const isServer = typeof window === "undefined"
const isFunction = fn => typeof fn === "function"

export const GA_TRACKING_ID = "UA-106978377-2"

export function sendEvent(eventName, eventParameters) {
  if (isServer) return
  if (!canTrack) {
    console.debug(`GA event: "${eventName}" with params ${JSON.stringify(eventParameters)}`)
    return
  }
  if (!isFunction(window.gtag)) return
  try {
    window.gtag("event", eventName, eventParameters)
  } catch (error) {
    sendToSentry(error)
  }
}

export function sendPageView(url) {
  if (isServer) return
  if (!canTrack) {
    console.debug(`GA page view: ${url}`)
    return
  }
  try {
    window.gtag("config", GA_TRACKING_ID, { page_path: url })
  } catch (error) {
    sendToSentry(error)
  }
}

export function sendLeadEvent(email, formName) {
  sendEvent("click", { event_category: formName, event_label: email ? "lead" : "lead call back" })
}

function sendToSentry(error) {
  withScope(scope => {
    scope.setFingerprint(["Gtag error"])
    captureException(error)
  })
}
