export const MARKETING_FIELDS = [
  "gclid",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_adgroup",
  "utm_content",
  "utm_term",
  "utm_retarget_campaign",
  "adposition",
  "device",
  "loc_physical_ms",
  "matchtype",
  "adid",
  "originalUrl"
]

export const TESTIMONIALS = [
  {
    id: 1,
    name: "Miki",
    content:
      "La entrega del coche ha sido la acordada, incluso 2 semanas antes. Estoy muy contento con Swipcar, ya que me ha dado la oportunidad de disfrutar mi coche. Volveré a renovar mi renting con ellos, seguro.",
    image: "testimonials/miki",
    car: "Peugeot 3008 BlueHDI Active",
    rating: 5
  },
  {
    id: 2,
    name: "Mildred",
    content:
      "Dentro de la cuota mensual está incluido el mantenimiento, el seguro... ¡Todo! Y eso me resulta muy cómodo, me despreocupo de las gestiones del coche y me limito a disfrutarlo.",
    image: "testimonials/mildred",
    car: "Fiat 500 1.2 Loungue",
    rating: 5
  },
  {
    id: 3,
    name: "Manuel",
    content:
      "Hace unos años estaba bien considerado utilizar coches diesel, ahora está penalizado y quién sabe las restricciones que se impondrán el año que viene. Ante esta incertidumbre, prefiero tener un coche de renting y cambiarlo por otro cuando me interese.",
    image: "testimonials/manuel",
    car: "Seat Arona 1.0 TSI Style Edition Eco",
    rating: 5
  },
  {
    id: 4,
    name: "Gisela",
    content:
      "Realizo muchos kilómetros y el renting me resulta más rentable a final del año. Estoy encantada con mi coche y, por ahora, las gestiones con Swipcar han ido genial.",
    image: "testimonials/gisela",
    car: "Seat Arona 1.0 TSI Style Edition Eco",
    rating: 5
  },
  {
    id: 5,
    name: "Maria",
    content:
      "Me salía más a cuenta el renting que la compra, ya que me ahorraba la cuota de entrada, una cantidad que no me venía bien pagarla de golpe.",
    image: "testimonials/maria",
    car: "Audi A1",
    rating: 5
  },
  {
    id: 6,
    name: "Juan Carlos",
    content:
      "Recomiendo Swipcar, el trámite ha sido simple y rápido. Estoy encantado con mi Peugeot 3008 ¡Solo puedo decir que es increible!",
    image: "testimonials/juan-carlos",
    car: "Seat Arona 1.0 TSI Style Edition Eco",
    rating: 5
  },
  {
    id: 7,
    name: "Juan Carlos",
    content:
      "Creo que con la cuota del todo incluido del renting me ahorro dinero. Estoy harto de comprar coches y malvenderlos al cabo de unos años.",
    image: "testimonials/juan-carlos-3008",
    car: "Peugeot 3008 BlueHDI Active",
    rating: 5
  },
  {
    id: 8,
    name: "Jose",
    content:
      "Estoy muy contento con mi Seat Leon y, por supuesto, con el renting de Swipcar, es muy completo en todo y satisfactorio.",
    image: "testimonials/jose",
    car: "Seat ibiza 1.0 EcoTSI Style",
    rating: 5
  }
]
