import { captureException } from "@sentry/node"
import axios from "axios"
import pickBy from "lodash/pickBy"

const apiUrl = process.env.NEXT_PUBLIC_API_URL

export function newOpportunity(params) {
  return axios.post(
    `${apiUrl}leads/new_opportunity`,
    pickBy(params, v => v !== undefined && v !== null)
  )
}

export function update(id, params) {
  return axios.put(
    `${apiUrl}leads/${id}`,
    pickBy(params, v => v !== undefined && v !== null)
  )
}

export function sendFingerprint(code) {
  if (!code) return null
  return axios.get(`${apiUrl}leads/code?code=${code}`).catch(err => captureException("axios rejected", { extra: err }))
}
