import { MARKETING_FIELDS } from "helpers/constants"
import { init as initClientFingerprint } from "helpers/fingerprint"
import { createCookie } from "helpers/marketingCookies"
import { Router } from "next/router"
import queryString from "query-string"
import { sendPageView as sendBingPageView } from "scripts/BingAds"
import { init as initFB, sendPageView as sendFBPageView } from "scripts/FacebookPixel"
import { sendPageView as sendGAPageView } from "scripts/GoogleAnalytics"
import { init as initGTM } from "scripts/GoogleTagManager"
import { init as initHotjar } from "scripts/Hotjar"
import { init as initSentry } from "scripts/Sentry"

const isServer = typeof window === "undefined"
let lastPath = !isServer && window.location.pathname

export default function initializers() {
  initSentry()

  if (isServer) return
  const { pathname } = window.location

  console.debug("Initialize")

  initHotjar()
  initGTM(pathname)
  initFB()
  initClientFingerprint()
  Router.events.on("routeChangeComplete", url => {
    const path = url.split("?")[0]
    if (lastPath === path) return
    lastPath = path
    sendGAPageView(path)
    sendBingPageView(path)
    sendFBPageView()
  })

  const { keyword, ...rest } = queryString.parse(window.location.search)
  createCookie('originalUrl', window.location.href)
  MARKETING_FIELDS.forEach(field => {
    createCookie(field, rest[field])
  })
}
