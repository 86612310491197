import { init as initSentry, Integrations, setTag, setUser } from "@sentry/node"

const isServer = typeof window === "undefined"

export function init() {
  initSentry({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    integrations: [new Integrations.InboundFilters({ ignoreErrors: [/ChunkLoadError/] })]
  })

  if (!isServer) setTag("country", "es")
}

export function personTracking(data) {
  if (!data) return
  setUser({
    id: data.id,
    username: data.name,
    email: data.email
  })
}
