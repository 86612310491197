import "@fortawesome/fontawesome-svg-core/styles.css"
import "@/index.css"

import { config, library } from "@fortawesome/fontawesome-svg-core"
import * as PropTypes from "prop-types"
import { useEffect } from "react"
import { ThemeProvider } from "styled-components"

import { FormContextProvider } from "@/components/FormContext"
import GlobalStyle from "@/components/styled/GlobalStyles"
import * as fam from "@/lib/fontAwesome"
import { init as initAxios } from "@/lib/withAxios"
import theme from "@/theme"

import initializers from "../src/Initializers"

config.autoAddCss = false
library.add(fam)
initAxios()
export default function MyApp(props) {
  const { Component, pageProps, err } = props

  useEffect(initializers, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <FormContextProvider>
          <GlobalStyle />
          <Component {...pageProps} err={err} />
        </FormContextProvider>
      </ThemeProvider>
    </>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
}
