import { captureException, withScope } from "@sentry/node"
import ReactPixel from "react-facebook-pixel"

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
const isServer = typeof window === "undefined"

export function init() {
  const options = {
    autoConfig: true,
    debug: !isProduction
  }
  setTimeout(() => {
    ReactPixel.init("534896427113023", {}, options)
    sendPageView()
  }, 3000)
}

export function sendPageView() {
  if (isServer) return
  try {
    ReactPixel.pageView()
  } catch (error) {
    sendToSentry(error)
  }
}

export function sendLead() {
  if (isServer) return
  try {
    ReactPixel.track("Lead", {})
  } catch (error) {
    sendToSentry(error)
  }
}

function sendToSentry(error) {
  withScope(scope => {
    scope.setFingerprint(["FbPixel error"])
    captureException(error)
  })
}
