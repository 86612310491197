import { captureException } from "@sentry/node"
import { sendFingerprint } from "services/leadsService"

let clientFingerprint = null

export function init() {
  setTimeout(() => {
    generateFingerprint()
      .then(code => {
        clientFingerprint = code
        sendFingerprint(code)
      })
      .catch(err => {
        console.log(err)
        captureException("Error generating fingerprint", { extra: err })
      })
  }, 4000)
}

export async function generateFingerprint() {
  const FingerprintJS = await import("@fingerprintjs/fingerprintjs")
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  const { visitorId } = result
  return visitorId
}

export function getClientFingerprint() {
  return clientFingerprint
}
