import { captureException } from "@sentry/node"
import axios from "axios"
import isEmpty from "lodash/isEmpty"

export function init() {
  axios.interceptors.response.use(
    response => response.data,
    error => {
      let jsonResponse = { httpStatus: error.request.status }
      const data = {
        request: error.config.data,
        response: error.request.response
      }
      if (error.request.status >= 500) {
        captureException("Server down", { extra: data })
        return Promise.reject({})
      }
      try {
        if (!isEmpty(error.request.response)) {
          const response = JSON.parse(error.request.response)
          jsonResponse = { ...response.errors, httpStatus: error.request.status }
        }
      } catch (e) {
        captureException("Error is not JSON", { extra: data })
      }
      return Promise.reject(jsonResponse)
    }
  )
}
