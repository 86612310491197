const theme = {
  colors: {
    primary: "#6decb9",
    secondary: "#1f8efa",
    darkBlue: "#242e42",
    greyBlue: "#6780aa",
    clearBlue: "#1f8efa",
    lightGreenishBlue: "#6decb9",
    navyBlue: "#0f4aa0",
    blue: "#1c67e5",
    yellow: "#ffbe0b",
    pink: "#e83f94",
    darkGrey: "#9b9eb1",
    grey: "#e1e6ee",
    lightGrey: "#f7f7fa",
    white: "#ffffff",
    crimson: "#e60d42",
    watermelon: "#fe4164",
    dusk: "#3c4c69",
    scBlue: "#72adf1",
    purple: "#c162f9",
    success: "#4caf50",
    veryLightBlue: "#e1e6ee",
    lightMint: "#bcffc5"
  },
  breakpoints: {
    xs: "(min-width: 375px)",
    sm: "(min-width: 576px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 992px)",
    xl: "(min-width: 1200px)"
  }
}

export default theme
