import isFunction from "lodash/isFunction"

import { GA_TRACKING_ID, sendPageView } from "./GoogleAnalytics"

export function init(pathname) {
  setTimeout(() => {
    if (!isFunction(window.gtag)) return

    window.gtag("js", new Date())

    window.gtag("config", GA_TRACKING_ID, { optimize_id: "GTM-WRRMRD7" })
    window.gtag("config", "AW-691495311")
    if (pathname) sendPageView(pathname)
  }, 0)
}

export function setCookieConsent(accepted) {
  const status = accepted ? "granted" : "denied"

  window.gtag("consent", "update", {
    ad_storage: status
  })
}
