import { createContext, useState } from "react"

const FormContext = createContext({})

function FormContextProvider({ children }) {
  const [formState, setFormState] = useState({})

  return <FormContext.Provider value={[formState, setFormState]}>{children}</FormContext.Provider>
}

export { FormContext, FormContextProvider }
